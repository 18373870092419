const ComprasRoutes = [
   {
     path: "/compras",
     name: "IndexCompras",
     meta: {
       requiresAuth: true,
       title: "Notas de Entrada"
     },
     component: () => import( "@/views/compras/IndexCompras.vue" )
   },
   {
     path: "/compras/inserir",
     name: "InsertCompras",
     meta: {
       requiresAuth: true,
       title: "Cadastro de Nota de Entrada"
     },
     component: () => import( "@/views/compras/ManageCompras.vue" )
   },
   {
     path: "/compras/alterar",
     name: "AlterCompras",
     meta: {
       requiresAuth: true,
       title: "Editar Cadastro de Nota de Entrada"
     },
     component: () => import( "@/views/compras/ManageCompras.vue" )
   }
 ]
 
 export default ComprasRoutes
 