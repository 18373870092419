import api from "@/config/api"
class CompraProdutosController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/compraproduto/registros/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosController getLength." );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/compraproduto/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.CMPP_ID;
          item.text = item.CMPP_DESCRICAO;
        });
      }
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/compraproduto", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/compraproduto/${ req.COMP_ID }/${ req.CMPP_COMP_EMP_ID }/${ req.CMPP_COMP_SERIE }/${ req.CMPP_COMP_FORN_ID }/${ req.CMPP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/compraproduto/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }/${ req.CMPP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosController delete." );
      throw error
    }
  }
  async updateLoop ( req ) {
    try {
      const response = await api.post( "/compraproduto/atualizacaogeral", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraProdutosController updateLoop." );
      throw error
    }
  }
}

export default new CompraProdutosController()
