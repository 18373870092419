import api from '@/config/api';
class MonitoramentoBackupController {
  /* eslint-disable */
  async search() {
    try {
      console.log('entrou no search');
      let response = await api.get(`/dadosbackup`);
      console.log('response:', response);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.UNID_ID;
          item.text = item.UNID_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new MonitoramentoBackupController();