const monitoramentoServidorRoutes = [
    {
        path: '/monitoramentobackupbancos',
        name: 'MonitoramentoBackupBancos',
        meta: {
            requiresAuth: true,
            title: "Monitoramento de Backups de Banco de Dados",
        },
        component: () => import('../../../../views/monitoramentoServidor/MonitoramentoBackupBancos.vue')
    },
]
export default monitoramentoServidorRoutes;