import api from "@/config/api"
class AreasController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/area/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em AreasController getLength." );
      throw error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/area/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id =  item.AREA_ID;
          item.text = item.AREA_DESCRICAO;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em AreasController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/area", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AreasController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/area/${ req.AREA_ID }/${ req.AREA_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AreasController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/area/${ req.ID }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AreasController delete." );
      throw error
    }
  }
  async search ( req ) {
    try {
      const response = await api.get( `/area/pesquisar/${ req.ID }/${ req.EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AreasController search." );
      throw error
    }
  }
  async list ( req ) {
    try {
      const response = await api.post( `/area/${ req.ID }/${ req.EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em AreasController list." );
      throw error
    }
  }
}

export default new AreasController()
