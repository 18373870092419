import api from "@/config/api"
class ComprasController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/compra/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController getLength." );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/compra/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.COMP_ID;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      console.error( error || "Erro em ComprasController index." );
      throw error
    }
  }
  async list ( req ) {
   try {
      const response = await api.post( `/compra/filtrocontador/${ req.EMP_ID }`, req );
      if ( response.data?.dados?.code != 500 ) {
        response.data?.dados?.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
          item.id = item.COMP_ID;
        });
      }
      response.data.dados.total = response.data?.count?.TOT_REGISTROS;
      return response.data?.dados;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController list." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/compra", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/compra/${ req.ID }/${ req.COMP_EMP_ID }/${ req.COMP_SERIE }/${ req.COMP_FORN_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/compra/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController delete." );
      throw error
    }
  }
  async updateImage ( req ) {
    try {
      let formData = new FormData();
      formData.append( "file", req.IMAGE );
      const response = await api.put( `/compra/uploads/${ req.ID }/${ req.EMP_ID }//${ req.SERIE }/${ req.FORN_ID }/${ req.ID_IMG }`, formData, { headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController updateImage." );
      throw error;
    }
  }
  async deleteImage ( req ) {
    try {
      const response = await api.delete( `/compra/uploads/${ req.ID }/${ req.EMP_ID }//${ req.SERIE }/${ req.ID_IMG }/${ req.FORN_ID }/${ req.NAME_IMAGE }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController deleteImage." );
      throw error;
    }
  }
  async totals ( req ) {
    try {
      const response = await api.get( `/compra/totalizadoresmanual/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController totals." );
      throw error
    }
  }
  async finish ( req ) {
    try {
      const response = await api.post( `/compra/finalizar/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em ComprasController totals." );
      throw error
    }
  }

}

export default new ComprasController()
