<template>
  <v-list dense v-if="items.length > 0" class="menu-list mt-12">
    <v-list-group
      :value="true"
      v-for="level1 in items"
      :key="level1?.id"
      v-model="level1.active"
      no-action
      append-icon=""
    >
      <!-- nivel 1 -->
      <template v-slot:activator>
        <v-list-item-title class="white--text">
          <template v-icon:prepend>
            <v-icon color="white" class="mr-1">{{ level1?.PROG_IMG_WEB }}</v-icon>
          </template>
          {{ level1?.PROG_NOME }}
        </v-list-item-title>
        <template v-icon:append>
          <v-icon color="white" class="mr-1">{{ level1.active ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </template>
      </template>
      <!--nivel 2-->
      <template v-for="level2 in level1.NIVEL2">
        <v-list-group
          :value="true"
          v-if="level2?.PROG_NOME"
          :key="level2?.id"
          v-model="level2.active"
          @click="handleClick( level2 )"
          :no-action="level2?.PROG_DIR_WEB || level2?.PROG_EXE ? false : true"
          sub-group
          prepend-icon=""
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="white--text ml-2">
                {{ level2?.PROG_NOME }}
              </v-list-item-title>
            </v-list-item-content>
            <template v-icon:append-outer>
              <v-icon color="white" class="">
                {{
                  level2?.NIVEL3?.length > 0
                    ? level2.active
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                    : ""
                }}</v-icon
              >
            </template>
          </template>
          <!-- nivel 3-->
          <template v-for="level3 in level2.NIVEL3">
            <v-list-item
              v-if="level3?.PROG_DIR_WEB || level3?.PROG_EXE"
              v-model="level3.active"
              :key="level3?.id"
              @click="handleClick( level3 )"
            >
              <v-list-item-title class="white--text" :class="level2?.PROG_NOME == 'PRODUTOS' ? '' : 'ml-n10'">
                {{ level3?.PROG_NOME }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list-group>
  </v-list>
</template>
<script>
import api from "../../../config/api";
export default {
  props: {
    user: String,
    items: Array,
    programas: Array
  },
  name: "MenuSection",
  mounted() {
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
    this.$nextTick( () => {
      console.log( "produtos: ", this.items[ 6 ].NIVEL2[ 6 ] );
    });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  methods: {
    async openExe(req) {
      if (req) {
        if (this.$isMobile) {
          return this.$snotify.warning("Tela só disponível no desktop!");
        }
        if (!localStorage.getItem("locaHash")) {
          return this.$snotify.warning(
            "Sistema de abertura de aplicativos não disponível nessa máquina!"
          );
        }
        let form = {
          abrexe_emp_id: 1,
          abreexe_usr_id: this.user,
          abreexe_hahs: localStorage.getItem("locaHash"),
          abreexe_programa: req,
          abreexe_status: 0,
          abreexe_data: this.$func.convertToApiDate(
            new Date().toLocaleDateString()
          ),
        };
        await api.post(`/telasistema`, form);
        this.$snotify.success("Aguarde, estamos abrindo o seu app...");
      }
    },
    async router(req) {
      const { url, newTab } = req;
      if (url && this.$route.path != url) {
        this.clearCache();
        if(newTab){
          const tab = this.$router.resolve(url).href;
          return window.open(tab, '_blank');
        }
        return await this.$router.push(url);
      }
    },
    clearCache() {
      sessionStorage.clear();
    },
    keyDownHandler(event) {
      if (event.ctrlKey) {
        this.ctrlPressed = true;
      }
    },
    keyUpHandler(event) {
      if (!event.ctrlKey) {
        this.ctrlPressed = false;
      }
    },
    handleClick(option) {
      option?.PROG_DIR_WEB
                  ? this.router({url: option?.PROG_DIR_WEB, newTab: this.ctrlPressed})
                  : this.openExe(option?.PROG_EXE)
    }
  },
};
</script>
<style scoped>
.menu-list {
  max-height: calc(90vh - 10%); /* Ajuste a altura conforme necessário */
  overflow-y: auto;
  z-index: 1;
}

</style>