import api from "@/config/api"
class CompraFreteController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/comprafrete/registros/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CompraFreteController getLength." );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/comprafrete/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
        });
      }
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraFreteController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/comprafrete", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraFreteController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/comprafrete/${ req.COMP_ID }/${ req.CMFT_COMP_EMP_ID }/${ req.CMFT_COMP_SERIE }/${ req.CMFT_COMP_FORN_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraFreteController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/comprafrete/${ req.ID }/${ req.EMP_ID }/${ req.SERIE }/${ req.FORN_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraFreteController delete." );
      throw error
    }
  }
}

export default new CompraFreteController()
