const SaldoEstRoutes = [
  {
    path: "/saldoestoque",
    name: "IndexSaldoEstoque",
    meta: {
      requiresAuth: true,
      title: "Entradas e Saidas Manuais de Estoque"
    },
    component: () => import( "@/views/saldoEstoque/IndexSaldoEstoque.vue" )
  },
  {
    path: "/saldoestoque/inserir",
    name: "InsertSaldoEstoque",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Entradas e Saidas Manuais de Estoque"
    },
    component: () => import( "@/views/saldoEstoque/ManageSaldoEstoque.vue" )
  },
  {
    path: "/saldoestoque/alterar",
    name: "AlterSaldoEstoque",
    meta: {
      requiresAuth: true,
      title: "Editar Entradas e Saidas Manuais de Estoque"
    },
    component: () => import( "@/views/saldoEstoque/ManageSaldoEstoque.vue" )
  }
]

export default SaldoEstRoutes
