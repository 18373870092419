import api from '../config/api';
class SaldoEstoqueController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/saldoestoque/registros/${ req.EMP_ID }`, req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/saldoestoque/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async create ( req ) {
    try {
      let response = await api.post( '/saldoestoque', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async update ( req ) {
    try {
      let response = await api.put( `/saldoestoque/${ req.SALD_ID }/${ req.SALD_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/saldoestoque/${ req.SALD_ID }/${ req.SALD_EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new SaldoEstoqueController();