import api from "@/config/api"
class CompraParcelasController {
  async getLength ( req, res ) {
    try { 
      const total = await api.post( `/comprapagamentos/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CompraParcelasController getLength." );
      throw  error
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/comprapagamentos/filtros/${ req.EMP_ID }`, req );
      if ( response.data.code != 500 ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 == 0 ? 0 : 1;
        });
      }
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraParcelasController index." );
      throw error
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/comprapagamentos", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraParcelasController create." );
      throw error
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/comprapagamentos/${ req.CMPD_ID }/${ req.CMPD_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraParcelasController update." );
      throw error
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/comprapagamentos/${ req.ID }/${ req.EMP_ID }` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraParcelasController delete." );
      throw error
    }
  }
}

export default new CompraParcelasController()
